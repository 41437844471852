.viewCardGeneral {
    perspective: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    transition: transform 0.3s ease;
    height: fit-content;
    width: fit-content;
}

.viewCard:hover {
    transform: scale(1.05);
}

.viewCard {
    perspective: 2000px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: transform 0.6s ease;
    /* height: auto;
    width: auto; */
    /* background-color: red; */
}


.rotating-div {
    transform-style: preserve-3d;
    transition: transform 0s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.front,
.back {
    width: 100%;
    height: fit-content;
    position: absolute;
    backface-visibility: hidden;
    z-index: 2;
}

.front {
    background-color: #3498db;
    background: linear-gradient(150deg, rgb(255 255 255 / 15%), transparent);
}

.back {
    background-color: #e74c3c;
    background: linear-gradient(250deg, rgb(255 255 255 / 15%), transparent);
}