@font-face {
  font-family:MadeTommyLight;
  src: url(madetommylight.otf);
}
@font-face {
  font-family:MadeTommyMeidum;
  src: url(madetommymedium.otf);
}

/* button {
  display: flex !important;
  align-items: center;
} */

.barnav{
  border-bottom: solid #00a79d 4px;
  background-color: #000 !important;
}

.logoNav {
  height: 50px;
  width: auto;
}

/* #00a79d */

.App {
  padding-bottom: 300px;
  background: #fff url(./img/elements/wallpaper.png) center center/cover no-repeat fixed;
  /* background-image: url(./img/elements/wallpaper.png); */
  /* background: linear-gradient(45deg, #832694 0%, #2E9475 100%); */
  background-color: rgb(244, 244, 244);
  min-width: 904px;
  /* display: flex;
  flex-wrap: wrap;
  width: fit-content;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; */
}

.selectDesktop {
  height: 100%;
  width: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  text-align: center;
  left: 0;
  z-index: 9999;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selectDesktop h3 {
  position: absolute;
  top: 20px;
  left: 20px;
  color: wheat;
}

.selectDesktop button {
  margin: 0 10px;
}

/* .viewGeneral {
  
} */



.cardView {
  transform: scale(.5);
  height: auto;
  width: fit-content;
}

.cart {
  transition: all .3s ease;
  height: 1280px;
  width: 904px;
  background-color: gray;
  /* background-image: url(img/cazador.png); */
  position: relative;
  background-size: contain;
}

/* .viewGeneral .cart:hover {
  transform: scale(1.2);
} */


.imageCharacter {
  width: 774px;
  height: 747px;
  overflow: hidden;
  position: absolute;
  bottom: 393px;
  left: 65px;
}

.cutimage {
  height: 828px;
  width: 774px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageCharacter .imageCharacter {
  width: auto;
}

.options {
  display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    width: 600px;
    justify-content: center;
    margin: 0 auto;
}

h3 {
  margin: 5px 0;
  font-size: 23px;
  font-weight: 100;
}

input, select {
  width: 90%;
  font-size: 20px;
  margin-left: 20px;
}

.divider {
  height: 2px;
  width: 100%;
  margin: 15px 0;
  background-color: black;
}

/* Nombre */

.nameCard {
  width: 905px;
  height: 1280px;
  position: absolute;
  overflow: hidden;
  z-index: 10;
}

.nameCard img{
  width: 100%;
  filter: contrast(0.7);
  height: 100%;

}

.nameCard .text {
  position: absolute;
  top: 63px;
  left: 136px;
  height: 70px;
  width: 70%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}


.nameCard .text h2 {
  font-family: "MadeTommyMeidum";
  /* font-size: 49px; */
  text-align: center;
  margin: 0;
  letter-spacing: 5px;
  
}

/* Ataques */

.attacks {
  position: absolute;
  top: 773px;
  height: 103px;
  left: 79px;
  width: 751px;
}

.attacks .one, .attacks .two {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 101px;
  width: 101px;
}

.attacks .one div h2, .attacks .two div h2 {
  color: #D4C9B8;
  font-family: "MadeTommyMeidum" !important;
  font-size: 55px;
  margin: 0px !important;
}

.attacks .one div img, .attacks .two div img {
  width: 64px;
  height: auto;
}

.attacks .one {
  position: absolute;
  top: 0;
  left: 1px;
}

.attacks .two {
  position: absolute;
  top: 0;
  right: 0;
}

.textTypeAction {
  position: absolute;
  bottom: 280px;
  left: 61px;
  z-index: 999;
}

.textTypeAction h5 {
  margin-bottom: 0;
  font-size: 28px;
}

/* color: #D4C9B8;
font-family: "MadeTommyLight" !important;
font-size: 36px;
height: 101px;
width: 101px; */

/* .attacks .one {
  font-family: "MadeTommyLight" !important;
  position: absolute;
  color: #D4C9B8;
  top: 0;
  margin: 0; 
  left: 0px;
  font-size: 36px;
  height: 101px;
  width: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attacks .one img {
  width: 64px;
  height: auto;
}

.attacks .two {
  font-family: "MadeTommyLight";
  position: absolute;
  color: #D4C9B8; 
  bottom: 0px;
  margin: 0;
  right: 0;
  font-size: 36px;
  height: 101px;
  width: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attacks .two div img {
  width: 64px;
  height: auto;
  margin: 9px 0 0 3px;
}

.attacks .one, .attacks .two {
  margin: 0px !important;
} */

/* Descripcion */

.descripcion {
  position: absolute;
  bottom: 95px;
  border-radius: 20px;
  left: 58px;
  height: 180px;
  width: 783px;
  z-index: 11;
  overflow: hidden;
  font-size: 24px;
  display: flex;
  /* align-items: center; */
}

.descripcion div {
  font-size: 30px !important;
}

.descripcion p {
  
  margin: 0;
  line-height: 1.1;
  text-align: justify;

}

.cocoros {
  position: absolute;
  bottom: 285px;
  right: 61px;
  height: 30px;
  width: 87%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cocoros img {
  margin: 0 10px;
  height: 25px;
  width: auto;
}

.cocoros .blue {
  filter: hue-rotate(0deg) drop-shadow(0px 0px 0px #000) brightness(1) contrast(1.5);
}
.cocoros .red{
  filter: brightness(1.0) contrast(1.5) drop-shadow(0px 0px 0px #000);
}
.cocoros .gray {
  filter: brightness(2) drop-shadow(0px 0px 1px #000);
}

/* Typo */

.type {
  height: 45px;
  width: auto;
  position: absolute;
  top: 76px;
  right: 70px;
  z-index: 13;
}

.type2 {
  height: 45px;
  width: auto;
  position: absolute;
  top: 76px;
  left: 70px;
  z-index: 13;
}

/* Image */

.editbox1 {
  background-color: #d2d2d2;
  width: 600px;
  padding: 20px;
}

.editbox2{
  /* background-color: #efefef; */
  width: 600px;
  padding: 20px;
}

.descarga {
  background-color: goldenrod;
  color: white;
  padding: 10px 15px;
  font-size: 20px;
  border: 0px;
  border-radius: 10px;
}

.descarga:hover {
  background-color: rgb(161, 125, 33);
  cursor: pointer;
}

.sku {
  font-family: "MadeTommyMeidum";
  font-size: 15px;
  position: absolute;
  bottom: 378px;
  width: 510px;
  left: 57px;
}

.ilus {
  font-family: "MadeTommyMeidum";
    position: absolute;
    font-size: 15px;
    bottom: 378px;
    right: 57px;
    text-align: right;
    z-index: 30;
    width: 300px;
}

.ilus p {
  mix-blend-mode: difference;
  color: #000;
  /* width: 500px; */
  /* transform: rotate(-90deg); */
  /* height: 30px; */
  /* position: absolute; */
  /* transform: rotateZ(-90deg); */
  /* top: 585px; */
  margin: 0;
  /* left: 50px; */

}



/* Nuevas Opciones */
.modalChangeCard {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: #000000be;
  /* background: linear-gradient(45deg, #832694 0%, #2E9475 100%); */
  top: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
}

.btnClose {
  position: absolute;
  top: 20px;
  right: 20px;
  /* font-size: 14px; */
  /* padding: 10px 20px; */
  /* border: solid 0px; */
  /* border-radius: 10px; */
}

/* .btnClose:hover {
  cursor: pointer;
  filter: brightness(1.1);
} */

.cardViewContent {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: space-around;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-direction: row;
}

.cardViewSelect {
  height: auto;
  width: 300px;
  margin: 20px;
  transition: all ease .2s;
  text-align: center;
  color: white;
  font-size: 18px;
  font-family: "MadeTommyMeidum";
}

.cardViewSelect:hover {
  transform: scale(1.2) ;
  cursor: pointer;
  /* box-shadow: 0 0 25px #000; */
}

.cardViewSelect img {
  height: auto;
  width: 100%;
  margin-top: 10px;
}

.sacrificios {
  position: absolute;
  bottom: 57px;
  right: 60px;
  height: 30px;
  width: 330px;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.sacrificios .iconSacrificio {
  width: 30px;
  height: auto;
  margin: 0 0 0px 10px;
  /* transform: rotate(45deg); */
}

.debilidades {
  position: absolute;
  bottom: 57px;
  left: 60px;
  height: 30px;
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.debilidades div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
}

.debilidades img {
  margin: 0 10px 0 0;
  height: 30px;
  width: auto;
}

.debilidades h5 {
  margin: 0;
  margin-right: 10px;
}

.seleccionDebRes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}


.seleccionDebRes img {
  height: 40px;
  width: auto;
  transition: all ease .1s;
}

.seleccionDebRes img:hover {
  cursor: pointer;
  transform: scale(1.2);
  -webkit-filter: drop-shadow(0px 0px 5px #2222228e);
  filter: drop-shadow(0px 0px 5px #2222228e);
}

.selectDebRes {
  transform: scale(1.2);
  -webkit-filter: drop-shadow(0px 0px 5px #d3cc11a6);
  filter: drop-shadow(0px 0px 5px #d3cc11a6);
}

.tras {
  transform: translateX(15px);
}

.access {
  height: 100%;
  width: 904px;
  position: absolute;
  z-index: 999;
  filter: opacity(.3);
  /* background-color: rebeccapurple; */
}

.access img{
  width: 904px;
  height: auto;
}