@media (min-width: 1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1520px;
    }
}

hr {
    width: 100%;
}
.form-label {
    font-weight: bold;
}
.optionsDesk {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 20px;
    background-color: #e4e4e433;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(6px);
}

.optionsDesk .width100 {
    width: 100% !important;
}

.optionsDesk .width90 {
    width: 90% !important;
}

.optionsDesk .widthFill {
    width: -webkit-fill-available;
}

.optionsDesk .selectTypeCard {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.optionsDesk .selectTypeCard h4 {
    margin: 0 15px;
    font-weight: bold;
    /* color: rgb(89, 21, 131); */
}

.optionsDesk button {
    box-shadow: 0 0 2px gray;
}

.optionsDesk input, .optionsDesk select {
    margin: 0 0px;
    width: -webkit-fill-available;
}

.custom-range::-webkit-slider-runnable-track {
    background-color: rgb(173, 173, 173);
}
.custom-range::-moz-range-track {
    background-color: rgb(173, 173, 173);
}
.custom-range::-ms-track {
    background-color: rgb(173, 173, 173);
}

.optionsDesk .attr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.viewGeneral {
    perspective: 2000px;
    /* box-shadow: 0px 0px 10px rgba(255, 255, 255, 0); */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: sticky;
    top: 0px;
    max-height: 100vh;
    transform: scale(0.5);
    transition: transform 0.3s ease;
}
 .viewGeneral:hover {
    transform: scale(0.53);
 }

.rotating-div {
    transform-style: preserve-3d;
    transition: transform 0s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rotating-div:hover {
    /* box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7); */
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  z-index: 9999;
}

.front {
  background-color: #3498db;
  background: linear-gradient(150deg, rgb(255 255 255 / 15%), transparent);
}

.back {
  background-color: #e74c3c;
  background: linear-gradient(250deg, rgb(255 255 255 / 15%), transparent);

  /* transform: rotateY(180deg); */
}