.imgCarrusel {
    min-height: 70vh;
    max-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoCarrusel1 {
    position: absolute;
    z-index: 1;
    height: auto;
    width: 65%;
    filter: drop-shadow(0px 0px 10px rgba(179, 209, 211, 0.445));
}

.callaction1 {
    background-color: white;
    height: 150px;
    width: 100%;
}

.cartasMuestra {
    height: 80vh;
    width: 100%;
    background-size: cover;
    background-image: url("../img/elements/fondo1.jpg");
}


.contentViewCardx {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}
